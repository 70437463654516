import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import { Dashboard, Login, ForgotPassword } from "./pages";
import ApprovedUserListing from "./pages/Admin/ApprovedUserListing";
import NotApprovedUserListing from "./pages/Admin/NotApprovedUserListing";
import SportsList from "./pages/sports/SportsList";
import LeagueListing from "./pages/Leagues/LeagueListing";
import TeamListing from "./pages/Teams/TeamListing";
import PlayersListing from "./pages/players/PlayersListing";
import AddUser from "./pages/Admin/AddUser";
import EditUser from "./pages/Admin/EditUser";
import ChangePassword from "./pages/Admin/ChangePassword";
import AddSport from "./pages/sports/AddSport";
import EditSport from "./pages/sports/EditSport";
import AddTeam from "./pages/Teams/AddTeam";
import EditTeam from "./pages/Teams/EditTeam";
import AddPlayer from "./pages/players/AddPlayer";
import EditPlayer from "./pages/players/EditPlayer";
import AddLeague from "./pages/Leagues/AddLeague";
import EditLeague from "./pages/Leagues/EditLeague";
import LeagueTeamsListing from "./pages/Leagues/LeagueTeamsListing";
import LeaguePlayersListing from "./pages/Leagues/LeaguePlayersListing";
import AddPlayers from "./pages/Leagues/AddPlayers";
import MainLeagueListing from "./pages/Leagues/MainLeagueListing";
import EditMainLeague from "./pages/Leagues/EditMainLeague";
import AddMainLeague from "./pages/Leagues/AddMainLeague";
import MatchListing from "./pages/Leagues/MatchListing";
// import AddMatch from "./pages/Leagues/AddMatch";
// import EditMatch from "./pages/Leagues/EditMatch";
import LeagueAssignAdmin from "./pages/Leagues/LeagueAssignAdmin";
import ChangeOldPassword from "./pages/Settings/ChangeOldPassword";
import NotApprovedClubListing from "./pages/Clubs/NotApprovedClubListing";
import AddClub from "./pages/Clubs/AddClub";
import EditClub from "./pages/Clubs/EditClub";
import ChangeClubPassword from "./pages/Clubs/ChangeClubPassword";
import ApprovedClubListing from "./pages/Clubs/ApprovedClubListing";
import ManagerListing from "./pages/Clubs/ManagerListing";
import AddManager from "./pages/Clubs/AddManager";
import EditManager from "./pages/Clubs/EditManager";
import MatchesListing from "./pages/Matches/MatchesListing";
import AddMatch from "./pages/Matches/AddMatch";
import EditMatch from "./pages/Matches/EditMatch";
import AddDivisionMatch from "./pages/Leagues/AddDivisionMatch";
import EditDivisionMatch from "./pages/Leagues/EditDivisionMatch";
import MatchesHistory from "./pages/Matches/MatchesHistory";
import MatchesHistoryDetails from "./pages/Matches/MatchesHistoryDetails";
import Calendar from "./pages/Matches/Calendar";
import AssignmentListing from "./pages/Assignment/AssignmentListing";
import AddAssignment from "./pages/Assignment/AddAssignment";

// ----------------------------------------------------------------------

const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* assignments */}
        <Route path="/assignment" element={<AssignmentListing />} />
        <Route path="/assignment/add-assignment" element={<AddAssignment />} />
        {/* <Route path="/assignment/edit-assignment/:id" element={<EditPlayer />} /> */}


        {/* Clubs */}
        <Route path="/clubs/approved-clubs" element={<ApprovedClubListing />} />
        <Route
          path="/clubs/not-approved-clubs"
          element={<NotApprovedClubListing />}
        />
        <Route path="/clubs/add-club" element={<AddClub />} />
        <Route path="/clubs/edit-club/:id" element={<EditClub />} />
        <Route
          path="/clubs/change-password/:id"
          element={<ChangeClubPassword />}
        />

        <Route path="/clubs/managers/:id" element={<ManagerListing />} />
        <Route path="/clubs/add-manager/:id" element={<AddManager />} />
        <Route path="/clubs/edit-manager/:id" element={<EditManager />} />

        {/* Admin Users */}
        <Route
          path="/admins/approved-users"
          element={<ApprovedUserListing />}
        />
        <Route
          path="/admins/not-approved-users"
          element={<NotApprovedUserListing />}
        />
        <Route path="/admins/add-user" element={<AddUser />} />
        <Route path="/admins/edit-user/:id" element={<EditUser />} />
        <Route
          path="/admins/change-password/:id"
          element={<ChangePassword />}
        />

        {/* settings / Change Old Password */}
        <Route
          path="/settings/change-old-password/"
          element={<ChangeOldPassword />}
        />

        {/* Matches */}
        <Route path="/matches" element={<MatchesListing />} />
        <Route path="/matches-history" element={<MatchesHistory />} />
        <Route path="/matches-history/:id" element={<MatchesHistoryDetails />} />

        <Route path="/matches/add-match/:id" element={<AddMatch />} />
        <Route path="/matches/edit-match/:id" element={<EditMatch />} />

        <Route path="/calendar" element={<Calendar />} />

        {/* Sports */}
        <Route path="/sports" element={<SportsList />} />
        <Route path="/sports/add-sport" element={<AddSport />} />
        <Route path="/sports/edit-sport/:id" element={<EditSport />} />

        {/* League */}
        <Route path="/leagues" element={<MainLeagueListing />} />
        <Route path="/leagues/add-main-league" element={<AddMainLeague />} />
        <Route
          path="/leagues/edit-main-league/:id"
          element={<EditMainLeague />}
        />

        <Route path="/leagues/league-listing/:id" element={<LeagueListing />} />
        <Route path="/leagues/add-league" element={<AddLeague />} />
        <Route path="/leagues/edit-league/:id" element={<EditLeague />} />
        <Route
          path="/leagues/teams-listing/:id"
          element={<LeagueTeamsListing />}
        />
        <Route
          path="/leagues/players-listing/:id"
          element={<LeaguePlayersListing />}
        />
        <Route path="/leagues/add-players/:id" element={<AddPlayers />} />

        <Route path="/leagues/matches-listing/:id" element={<MatchListing />} />
        <Route path="/leagues/add-match/:id" element={<AddDivisionMatch />} />
        <Route path="/leagues/edit-match/:id" element={<EditDivisionMatch />} />
        <Route
          path="/leagues/assign-club/:id"
          element={<LeagueAssignAdmin />}
        />

        {/* Teams */}
        <Route path="/teams" element={<TeamListing />} />
        <Route path="/teams/add-team" element={<AddTeam />} />
        <Route path="/teams/edit-team/:id" element={<EditTeam />} />

        {/* Players */}
        <Route path="/players" element={<PlayersListing />} />
        <Route path="/players/add-player" element={<AddPlayer />} />
        <Route path="/players/edit-player/:id" element={<EditPlayer />} />
      </Route>

      {/* Login */}
      <Route path="/login" element={<Login />} />

      {/* ForgotPassword */}
      <Route path="/forgotPassword" element={<ForgotPassword />} />
    </Routes>
  );
}
