import { Box, Typography, Drawer } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import SidebarConfig from "./SidebarConfig";
import NavSection from "./NavSection";
import { useNavigate } from "react-router-dom";
import { logo_image } from "../../assets";

const AccountStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  // padding: theme.spacing(2, 3.5),
  borderRadius: 15,
  cursor: "pointer",
}));

export default function DashboardSidebar({
  handleDrawerToggle,
  mobileOpen,
  drawerWidth,
}) {
  const navigate = useNavigate();
  const drawer = (
    <div>
      <Box sx={{    mt:2.5, mx: 7.5 ,height: 100, width: 100}}>
        <AccountStyle>
        <Box className="box">
          <Avatar
            className="bar-img"
            variant="square"
            // sx={{ height: 34, width: 34 }}
            alt="Remy Sharp" 
            src={logo_image}
          />
        </Box>
        </AccountStyle>
      </Box>
      <NavSection navConfig={SidebarConfig} />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className="app-sidebar-box">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  );
}
