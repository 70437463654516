import { invokeApi } from "../../bl_libs/invokeApi";

export const _list_assignment= async (data) => {
    let requestObj = {
      path: `api/assignment/list_assignment`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _get_instructor_batches= async () => {
    let requestObj = {
      path: `api/instructor/get_instructor_batches`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
       },
    };
    return invokeApi(requestObj);
  };

  export const _post_assignment= async (data) => {
 
    const requestObj = {
      path: `api/assignment/post_assignment`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
        //  "Content-Type": "multipart/form-data",
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };