import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { _add_admin } from "../../DAL/admin/admin";
import { _add_game, _get_all_games } from "../../DAL/game/game";
import { avatar_base_url, countries, s3baseUrl } from "../../config/config";
import { _add_team } from "../../DAL/teams/teams";
import { DateTimePicker } from "@mui/x-date-pickers";
import { _add_player } from "../../DAL/players/players";
import { _get_list_club } from "../../DAL/clubs/clubs";
import { _get_instructor_batches, _post_assignment } from "../../DAL/assignment/assignment";

const Input = styled("input")({
  display: "none",
});
function AddAssignment() {
  const navigate = useNavigate();
  const moment = require('moment');
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allGames, setAllGames] = useState([]);
  const [allClubs, setAllClubs] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    batch_id: "",
    total_marks: "",
 
    
    
  });
  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, dob: formattedDate }));
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";
   
    const parsedDate = moment(inputs.dob);
console.log('inputs.dob',inputs.dob);
console.log('parsedDate',parsedDate);

let data = {
    title:inputs.title,
    batch_id:inputs.batch_id,
    dead_line_date: parsedDate.format('YYYY-MM-DD'),
    total_marks:inputs.total_marks,
}
 
  
// Format the date as "Month Day, Year"
 

console.log('data test',data);
          setIsLoading(true);
          const result = await _post_assignment(data);

          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
    
  
    
  };

  const getAllgames = async () => {
    setIsLoading(true);
    const response = await _get_instructor_batches();
console.log('response _get_instructor_batches',response);
    if (response.code === 200) {
    //   const GameData = response.batches.map((game, index) => {
    //     let profileImage = {};

    //     profileImage = {
    //       src: s3baseUrl + game.image,
    //       alt: game.image,
    //     };

    //     return {
    //       ...game,
    //       image: profileImage,
    //     };
    //   });

      setAllGames(response.batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getAllClubs = async () => {
    const response = await _get_list_club({
      // "text":"zzz",
      approval_status: true,
    });

    console.log("response _get_list_club", response);

    if (response.code === 200) {
      const ClubData = response.list_club.map((club, index) => {
        let profileImage = {};

        profileImage = {
          src: s3baseUrl + club.image,
          alt: club.image,
        };

        const gameTitles = club.game.map((game) => game.title).join(", "); // Join game titles into a single string

        return {
          ...club,
          image: profileImage,
          gameTitle: gameTitles,
        };
      });

      setAllClubs(ClubData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // setIsLoading(true);
    // getAllClubs();
    getAllgames();
  }, []);
  //===================================================================
  if (isLoading) {
    return <CircularProgress className="centerd" color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Add Assignment</h3>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 1,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
         

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Title"
                variant="outlined"
                sx={{ width: "100%" }}
                required
                name="title"
                value={inputs?.title}
                onChange={handleChange}
              />
            </div>
            
            <div className="col-md-6 col-xs-12">
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">batches*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="batch_id"
                  value={inputs.batch_id}
                  required
                  label="batches"
                  onChange={handleChange}
                >
                  {allGames.map((game) => (
                    <MenuItem key={game._id} value={game._id}>
                      {game.name.charAt(0).toUpperCase() + game.name.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
  

            <div className="col-md-6 col-xs-12">
              <TextField
                id="outlined-basic_1"
                label="Total Marks"
                variant="outlined"
                sx={{ width: "100%" }}
                name="total_marks"
                type="number"
                value={inputs?.total_marks}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Dead Line Date *"
                  format="YYYY-MM-DD"
                  className="form-control"
                  name="date_of_birth"
                  value={inputs?.date_of_birth}
                  onChange={handleDateChange}
                  minDate={dayjs()} // Set the maximum date to today's date
                  // renderInput={(params) => <TextField {...params} />} // Assuming TextField is imported and available
                />
              </LocalizationProvider>
            </div>
  
 

            <div className="col-12 text-end">
              <button className="small-contained-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AddAssignment;
