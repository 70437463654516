import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {
  TextField,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { login } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useContentSetting } from "../../Hooks/ContentContext/ContentSettingState";
import { _admin_login, _login_by_contact_number } from "../../DAL/login/login";
import { _set_user_to_localStorage } from "../../DAL/localStorage/localStorage";
const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { handleUserInfo } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      contact_number: contactNumber,
      password: newPassword,
      type: 2,
    };

 
    const response = await _login_by_contact_number(data);
    console.log('response __response _login_by_contact_number ',response);
    if (response.code === 200) {
      localStorage.setItem("token", response.token);
      handleUserInfo(response.user);
      _set_user_to_localStorage(response.user);
      enqueueSnackbar(response.message, { variant: "success" }); //we have error message in res.message
      setIsLoading(false);
      navigate("/dashboard");
    } else {
      enqueueSnackbar(response.message, { variant: "error" }); //we have error message in res.message
      setIsLoading(false);
    }
  };

  return (
    <div>
      <HeaderStyle>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      </HeaderStyle>
      <div className="container-fluid">
        <div className="row w-100">
          <div className="col-sm-5 pt-5">
            <Container>
              <SectionStyle>
                <Typography
                  variant="h3"
                  sx={{ px: 5, mt: 10, mb: 5, fontSize: "2.5vw" }}
                >
                  Hi, Welcome Back
                </Typography>
                <img src={login} alt="Login" />
              </SectionStyle>
            </Container>
          </div>
          <div className="col-sm-7 pt-5">
            <Container>
              <ContentStyle>
                <Stack sx={{ mb: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Welcome To DDIT | Instructor Portal
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter your details below.
                  </Typography>
                </Stack>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Contact Number"
                      required
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />

                    <TextField
                      fullWidth
                      autoComplete="current-password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              <Icon
                                icon={showPassword ? eyeFill : eyeOffFill}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  {/* <a className="forgot-text" href="/forgotPassword">
                    Forgot Password?
                  </a> */}

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ my: 1 }}
                  ></Stack>

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    // onClick={() => navigate("/dashboard")}
                  >
                    Login
                  </Button>
                </form>
              </ContentStyle>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
